import { css } from "@emotion/react";
import "@fontsource/merriweather";
import { Link } from "gatsby";
import "normalize.css";
import React from "react";
import "../index.css";

const container = css`
    color: #232129;
    font-family: Merriweather, -apple-system, Roboto, sans-serif, serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const headerContainer = css`
    margin: 0 32px;
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const headerTextContainer = css`
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #232129;
`;

const headerText = css`
    font-size: 24px;
    margin: 16px;
`;

const navContainer = css`
    margin-top: 8px;
    display: flex;
    > * + * {
        margin-left: 16px;
    }
`;

const linkClass = (active: boolean) => css`
    color: #232129;
    ${!active &&
    css`
        text-decoration: none;
    `}
    :hover {
        text-decoration: underline;
    }
`;

interface Props {
    pagePath: string;
    title?: string;
}

const links = [
    {
        title: "Home",
        path: "/",
    },
    // {
    //     title: "About",
    //     path: "/about",
    // },
    // {
    //     title: "FAQ",
    //     path: "/faq",
    // },
    // {
    //     title: "Contact",
    //     path: "/contact",
    // },
] as const;

export const Page: React.FC<Props> = ({ children, pagePath, title }) => {
    return (
        <main css={container}>
            <div css={headerContainer}>
                <div css={headerTextContainer}>
                    <h1 css={headerText}>
                        Daily Train Tracks {title && ` - ${title}`}
                    </h1>
                </div>
                <div css={navContainer}>
                    {links.map((link) => (
                        <Link
                            to={link.path}
                            key={link.path}
                            css={linkClass(link.path === pagePath)}
                        >
                            {link.title}
                        </Link>
                    ))}
                </div>
            </div>
            {children}
        </main>
    );
};
